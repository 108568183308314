$font-family-heading: 'Alogo Display', 'Helvetica Neue', Helvetica, Arial, sans-serif;

@mixin font-heading {
    font-family: $font-family-heading;
    font-weight: 900;
}

@mixin font-section-title {
    font-family: $font-family-heading;
    font-size: 48px;
    font-weight: 600;
    line-height: 1.08365;
    letter-spacing: -0.003em;

    @include mobile {
        font-size: 34px;
    }
}

@mixin font-section-intro {
    font-family: $font-family-heading;
    font-size: 21px;
    font-weight: 400;
    line-height: 1.38105;
    letter-spacing: 0.011em;

    @include mobile {
        font-size: 18px;
    }
}
