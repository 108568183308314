// /!\ The only way to use opacity (alpha) with this since refactor of theme is hexa with 8 digits
:root {
    // Alogo colors
    --color-orange: #fb6340;
    --color-blue: #57a5e0;
    --color-blue-light: #57a5e04d; // #57a5e0 30%
    --color-blue-lighter: #57a5e000; // #57a5e0 0%
    --color-red: #ff5678;
    --color-red-light: #ff567880; // #ff5678 50%
    --color-red-lighter: #ff56784d; // #ff5678 30%
    --color-red-lightest: #ff567800; // #ff5678 0%
    --color-green: #49ffac;
    --color-green-light: #49ffac80; // #49ffac 50%
    --color-green-lighter: #49ffac33; // #49ffac 20%
    --color-gray: #9aa0a6;
    --color: #6249ff;
    --color-active: #6249ff;
    --color-active-light: #6249ffcc; // #6249ff 40%
    --color-link: #6249ff;
    --color-link-light: #6249ff0d; // #6249ff 5%
    --color-link-lighter: #6249ff00; // #6249ff 0%
    --color-text: #ffffff;
    --color-text-light: #ffffff66; // ##ffffff 40%
    --color-text-lighter: #a37e2c40; // #a37e2c 25%
    --color-text-secondary: #ffffff99; // ##ffffff 60%
    --color-text-dark: #6249ff;
    --color-text-input: #f9f9f9;
    --color-text-tab: #ffffff;
    --color-secondary: #f9f9f9;
    --color-secondary-light: #f9f9f980; // #f9f9f9 50%
    --color-secondary-lighter: #f9f9f933; // #f9f9f9 20%
    --color-background: #202124;
    --color-background-blue-hover: #57a5e033; // ##57a5e0 20%
    --color-background-secondary: #292a2d;
    --color-background-tertiary: #0000004d; // #000000
    --color-background-radient: #202124; // #000000
    --color-background-hover: #57a5e033; // ##57a5e0 20%
    --color-background-image: #f9f9f9;
    --color-background-image-light: #f9f9f933; // #f9f9f9 20%
    --color-map-path: #57a5e0;
    --color-map-text: #f9f9f9;
    --color-map-jump-extremities: #f9f9f9;
    --color-map-jump-stroke: #f9f9f9;
    --color-map-jump-river: #57a5e0;
    --color-map-jump-white: #f9f9f9;
    --color-map-jump-fault: #ff5678;
    --color-map-jump-clear: #49ffac;
    --color-text-white: #ffffff;
    --color-background-active: #0000004d; // #f0f0f0 50%
    --color-background-dark: #202124;
    --color-background-darkest: #292a2d;
    --color-background-darker: #0000004d; // #000000 30%
    --color-lightest-gray: #f0f0f0;
    --color-intermediate-before: #49ffac;
    // General colors
    --color-error: #ff5678;
    --color-important: #ff5678;
    --color-important-light: #ff56781a; // #ff5678 10%
    --color-success: #49ffac;
    // Form
    --color-label: #ffffff80; // ##ffffff 50%
    --color-border: #ffffff1a; // ##ffffff 10%
    // Font
    --font-family: 'Alogo Text', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    --font-weight: 400;
    --button-font-weight: 400;
    // logo
    --logo-max-width: 155px;
    --logo-max-height: 100px;
    --logo-align-self: auto;
}

[theme='rgs'] {
    // Rolex Grand Slam colors
    --color-orange: #fb6340;
    --color-blue: #57a5e0;
    --color-blue-light: #57a5e04d; // #57a5e0 30%
    --color-blue-lighter: #57a5e000; // #57a5e0 0%
    --color-red: #ff5678;
    --color-red-light: #ff567880; // #ff5678 50%
    --color-red-lighter: #ff56784d; // #ff5678 30%
    --color-red-lightest: #ff567800; // #ff5678 0%
    --color-green: #006038;
    --color-green-light: #00603880; // #006038 50%
    --color-green-lighter: #00603833; // #006038 20%
    --color-gray: #a6a7a8;
    --color: #a37e2c;
    --color-active: #d8d9da;
    --color-active-light: #006038b3; // #006038 70%
    --color-link: #a37e2c;
    --color-link-light: #a37e2c00; // #a37e2c 0%
    --color-text: #a37e2c;
    --color-text-light: #a37e2c66; // #a37e2 40%
    --color-text-lighter: #a37e2c40; // #a37e2 25%
    --color-text-secondary: #a37e2c99; // #a37e2 60%
    --color-text-dark: #006038;
    --color-text-input: #006038;
    --color-text-tab: #006038;
    --color-secondary: #f0f0f0;
    --color-secondary-light: #f0f0f080; // #f0f0f0 50%
    --color-secondary-lighter: #f0f0f033; // #f0f0f0 20%
    --color-background: #d8d9da;
    --color-background-blue-hover: #57a5e033; // ##57a5e0 20%
    --color-background-secondary: #006038;
    --color-background-tertiary: #f0f0f0;
    --color-background-radient: #f0f0f0;
    --color-background-hover: #57a5e033; // ##57a5e0  20%
    --color-background-image: #a37e2c;
    --color-background-image-light: #a37e2c33; // ##a37e2c  20%
    --color-map-path: #006038;
    --color-map-text: #202124;
    --color-map-jump-extremities: #a37e2c;
    --color-map-jump-stroke: #202124;
    --color-map-jump-river: #57a5e0;
    --color-map-jump-white: #f0f0f0;
    --color-map-jump-fault: #e20931;
    --color-map-jump-clear: #006038;
    --color-text-white: #ffffff;
    --color-background-active: #292a2d1a; // #292a2d 10%
    --color-background-dark: #202124;
    --color-background-darkest: #292a2d;
    --color-background-darkest-light: #292a2d1a; // #292a2d 10%
    --color-background-darker: #0000004d; // #000000 30%
    --color-lightest-gray: #f0f0f0;
    --color-intermediate-before: #49ffac;
    // General colors
    --color-error: #ff5678;
    --color-important: #ff5678;
    --color-important-light: #ff56781a; // #ff5678 10%
    --color-success: #006038;
    // Form
    --color-label: #a37e2c80; //  ##a37e2c 50%
    --color-border: #ffffff1a; // #ffffff 10%
    // Font
    --font-family: 'Rolex Grand Slam', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    --font-weight: 200;
    --button-font-weight: 600;
    // Logo
    --logo-max-width: 75;
    --logo-max-height: 100px;
    --logo-align-self: flex-start;
}

@import 'mixins';
@import 'screens';
@import 'fonts';
@import 'sizes';
@import 'durations';
@import 'box-shadows';
@import 'border-radius';
@import 'padding';
@import 'logos';

:export {
    global-mobile: $tablet - 1px;
    global-tablet: $tablet;
    global-desktop: $desktop;
    global-widescreen: $widescreen;
    global-color-link: var(--color-link);
    global-color-text: var(--color-text);
    global-color-error: var(--color-error);
    color-map-jump-extremities: var(--color-map-jump-extremities);
    color-map-jump-stroke: var(--color-map-jump-stroke);
    color-map-jump-river: var(--color-map-jump-river);
    color-map-jump-white: var(--color-map-jump-white);
    color-background: var(--color-background);
    color-secondary: var(--color-secondary);
    color-text-dark: var(--color-text-dark);
    color-map-jump-fault: var(--color-map-jump-fault);
    color-background-tertiary: var(--color-background-tertiary);
    color: var(--color);
    color-background-secondary: var(--color-background-secondary);
    color-background-radient: var(--color-background-radient);
    color-background-darkest: var(--color-background-darkest);
}

// TOAST Override the variables or import a file that overrides them
$vt-toast-background: #202124;
$vt-color-progress-default: white;
$vt-color-default: #202124;
$vt-text-color-default: white;
$vt-color-info: white;
$vt-text-color-info: white;
$vt-color-success: #4caf50;
$vt-text-color-success: white;
$vt-color-warning: #ffc107;
$vt-text-color-warning: white;
$vt-color-error: #ff5252;
$vt-text-color-error: white;
$vt-font-family: var(--font-family);

// Import the regular Vue Toastification stylesheets (or create your own)
@import 'vue-toastification/src/scss/_variables';
@import 'vue-toastification/src/scss/_toastContainer';
@import 'vue-toastification/src/scss/_toast';
@import 'vue-toastification/src/scss/_closeButton';
@import 'vue-toastification/src/scss/_progressBar';
@import 'vue-toastification/src/scss/_icon';
@import 'vue-toastification/src/scss/animations/_bounce';
