
















@import '@design';

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

// MOVE
.move {
    width: 80%;
}

// TITLES
.titles {
    text-align: center;
}
.riderName {
    @include font-heading;

    font-size: 2em;
    opacity: 0;
    transform: translateX(-100%);
}
.horseName {
    margin-top: 3px;
    font-size: 1.2em;
    font-weight: 400;
    opacity: 0;
    transform: translateX(100%);
}
.riderName,
.horseName {
    animation: names 1s ease forwards;
    animation-delay: 1.5s;
}

@keyframes names {
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

// Start Number
.message {
    margin-top: 60px;
    font-size: 16px;
    text-align: center;
    opacity: 0;
    transform: translateY(-100%);
    animation: date 500ms ease forwards;
    animation-delay: 2.5s;
}

@keyframes date {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
